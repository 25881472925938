<template>
  <div class="bg-white shadow-md rounded-xl py-14">
    <div class="max-w-4xl mx-auto">
      <form @submit.prevent="update" @keydown="form.onKeydown($event)">
        <div class="mb-6">
          <label
            for="email"
            class="text-sm font-medium text-gray-900 block mb-2"
            >Name</label
          >
          <input
            type="email"
            id="email"
            v-model="form.name"
            readonly
            class="
              bg-gray-50
              border border-gray-300
              text-gray-900
              sm:text-sm
              rounded-lg
              focus:ring-blue-500 focus:border-blue-500
              block
              w-full
              p-2.5
            "
            placeholder="name@flowbite.com"
            required=""
          />
        </div>

        <div class="mb-6">
          <label
            for="email"
            class="text-sm font-medium text-gray-900 block mb-2"
            >Permissions</label
          >

          <ul class="list-none">
            <li class="" v-for="item in allPermissions" :key="item.id">
              <label class="inline-flex items-center mt-3">
                <input
                  :id="item.id"
                  v-model="form.selectedPermissions"
                  type="checkbox"
                  class="form-checkbox h-4 w-4 text-blue-600"
                  :value="item.id"
                /><span class="ml-2 text-gray-700" :for="item.id">{{
                  item.display_name
                }}</span>
              </label>
            </li>
            <!-- ... -->
          </ul>
        </div>

        <div class="flex justify-end">
          <div class="">
            <button
              @click="$router.push('/settings')"
              class="
                bg-red-500
                text-white
                py-2
                px-6
                rounded-xl
                hover:bg-red-600
                ml-3
              "
            >
              Cancel
            </button>
            <button
              :disabled="form.busy"
              type="submit"
              class="
                bg-green-500
                text-white
                py-2
                px-6
                rounded-xl
                hover:bg-green-600
                ml-3
              "
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",

  layout: "settings",

  data: () => ({
    role: null,
    form: new Form({
      name: "",
      display_name: "",
      description: "",
      selectedPermissions: [], //
    }),

    allPermissions: [],

    permissions: "",
    role_permissions: "",
  }),

  mounted() {
    this.loadData(this.$route.params.id);
  },

  methods: {
    async loadData(id) {
      try {
        const { data } = await axios.get(`/role/${id}`);

        this.role = data.role;
        this.allPermissions = data.permissions;
        this.form.selectedPermissions = data.role_permissions;

        this.form.name = data.role.name;
        this.form.display_name = data.role.display_name;
        this.form.description = data.role.description;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },

    async update() {
      await this.form.patch(`/update_role/${this.$route.params.id}`);

      window.location.reload(true);
    },
  },
};
</script>

<style scoped>
ul {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}
</style>